import { apiService } from "../../services";
import { UserStatusModel } from "outbound-api-client/lib/models";
import { AppDispatch } from "../../store";
import { requestUserStatus, requestUserStatusFailure, requestUserStatusSuccess } from "../../store/slices/userStatus.slice";

const getUserStatusFromApi = async (): Promise<UserStatusModel> => {
  return apiService.instance.getUserStatus(null);
}

export const getUserStatus = async (dispatch: AppDispatch) => {
  dispatch(requestUserStatus());

  try {
    let userStatus = await getUserStatusFromApi();
    userStatus = JSON.parse(JSON.stringify(userStatus));

    dispatch(requestUserStatusSuccess(userStatus));
  } catch {
    dispatch(requestUserStatusFailure());
  }
}