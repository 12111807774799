import React from 'react';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { Form, Field, TextInput, TextAreaInput, DateInput, ProductRepeaterField } from '..';
import { BidSupportFormSchema, BidSupportFormValues } from './BidSupportForm.schema';
import { apiService } from '../../services';
import { EmailTemplateParam } from 'outbound-api-client/lib/models';
// import { apiService } from '../../services';
// import { InteractionEventType } from 'outbound-api-client/lib/models';

export const BidSupportForm: React.FC = () => {

  function fieldIsDate(field: unknown): field is Date {
    return typeof (field as Date).getMonth === 'function';
  }

  const onSubmit = (formValues: BidSupportFormValues, formikBag?: FormikHelpers<BidSupportFormValues>) => {
    try{

      const formData: EmailTemplateParam[] = [];
      formData.push(new EmailTemplateParam("formName", "Bid Support Tool"));

      Object.getOwnPropertyNames(formValues).forEach((val, inx) => {
        const data = formValues as Record<string, unknown>;
        if (fieldIsDate(data[val])) {
          formData.push(new EmailTemplateParam(val, (data[val] as Date).toISOString()));
          return;
        }

        if (Array.isArray(data[val])) {
          (data[val] as Record<string, unknown>[]).forEach((item, inx) => {
            Object.getOwnPropertyNames(item).forEach((property) => {
              formData.push(new EmailTemplateParam(`${val}_${inx}_${property}`, String(item[property])));
            })
          });

          return;
        }

        formData.push(new EmailTemplateParam(val, String(data[val])));
      });

      apiService.postInteractionWebForm('BidSupportSubmit', formData, "campaignsupport@out-bound.co.uk");

      if(formikBag) formikBag.resetForm();
      toast('Success! We have your bid support submission.', {type: toast.TYPE.SUCCESS });
    } catch {
      toast("We can't submit right now. Try again later.", {type: toast.TYPE.ERROR});
    }
  }

  return (
    <Form
      initialValues={{
        endCustomerName: '',
        expectedSaleDate: new Date(),
        preferredDistributor: '',
        additionalInfo: '',
        productSelection: [{
          productCategory: '',
          product: '',
          quantity: '',
          demoProductRequired: false
        }],
      }}
      schema={BidSupportFormSchema}
      onSubmit={onSubmit}
      showCancel={false}
    >
      <div>
        <fieldset>
          <Field
            name='endCustomerName'
            label='End Customer Name'
            component={TextInput}
          />
          <Field
            name='expectedSaleDate'
            label='Expected Sale Date'
            component={DateInput}
          />
          <Field
            name='preferredDistributor'
            label='Preferred Distributor'
            component={TextInput}
          />
        </fieldset>
        <h3>Products</h3>
        <fieldset>
          <ProductRepeaterField name='productSelection' />
        </fieldset>
        <fieldset className='m-top-1'>
          <Field
            name='additionalInfo'
            label='Additional Information For Building Up The Business Case'
            component={TextAreaInput}
            fieldClassNames='w-100 w-max-none'
          />
        </fieldset>
      </div>
    </Form>
  )
}