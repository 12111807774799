import React, { useState } from 'react';
import { FormikHelpers } from 'formik';
import { Form, Field, TextInput, FileInput, DateInput, ImageRadioInput, LoaderAnimated, SelectInput, Portal } from '../../components';
import { DealRegFormSchema, DealRegFormValues } from './DealRegForm.schema';
import { toast } from 'react-toastify';
//import { apiService } from '../../services';
import { OpportunityProductModel, OpportunityType, OpportunityStatus, InteractionEventType, EmailTemplateParam, SalesIncentiveOpportunityInputModel, SalesIncentiveOpportunityModel } from 'outbound-api-client/lib/models';
import { useNavigate, useParams } from 'react-router-dom';
import { useOpportunities } from './DealReg.hooks';
import { apiService, configService } from '../../services';
import { Button, Modal } from 'react-bootstrap';
import { DealRegFormFieldDataType, DealRegFormProps } from '../../services/config.service.types';

export const DealRegForm: React.FC = () => {

  const { campaignId } = useParams();
  const { opportunityId } = useParams();
  const isEdit = typeof opportunityId !== "undefined";
  const { opportunities, isLoading } = useOpportunities(null, isEdit ? opportunityId : null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState<DealRegFormValues | null>(null);
  const handleClose = () => setShow(false);

  let initialValues = configService.current().opportunityManagementProps.dealRegForm?.filter((drf: DealRegFormProps) => drf.campaignIdentifier === null || drf.campaignIdentifier === campaignId)[0].dealRegFormInitialValues;

  if (isEdit && !isLoading && opportunities.length === 1) {
    initialValues = {
      status: opportunities[0].status,
      manufacturer: opportunities[0].oem,
      customerName: opportunities[0].customerName,
      productType: opportunities[0].products[0].description,
      opportunityDetails: opportunities[0].details,
      estimatedSalesValue: opportunities[0].value,
      estimatedCloseDate: new Date(opportunities[0].opportunityDate)
    };

  }

  const confirmCloseAndSubmit = async () => {
    if (formData !== null) {
      const data = mapFormValues(formData);
      const upload = mapUpload(formData);

      if (data === null) {
        toast("We can't submit right now. Try again later.", { type: toast.TYPE.ERROR });
      }
      else {
        var model = await doSubmit(data, upload);
        await sendInteraction(formData, model);
        navigate(`../${campaignId}/mydeals`, { replace: true });
      }
    }
  }

  const onSubmit = async (formValues: DealRegFormValues, formikBag?: FormikHelpers<DealRegFormValues>) => {

    const data = mapFormValues(formValues);
    const upload = mapUpload(formValues);

    if (data === null) {
      toast("We can't submit right now. Try again later.", { type: toast.TYPE.ERROR });
    }
    else {
      switch (data.status) {
        case "WON":
        case "LOST":
          setShow(true);
          setFormData(formValues);
          break;
        default:
          var model = await doSubmit(data, upload);
          await sendInteraction(data, model);
          navigate(`../${campaignId}/mydeals`, { replace: true });
          if (formikBag) formikBag.resetForm();
          break;
      }
    }
  }

  function fieldIsDate(field: unknown): field is Date {
    return field !== null && typeof (field as Date).getMonth === 'function';
  }

  const sendInteraction = async (formValues: DealRegFormValues, model: SalesIncentiveOpportunityModel) => {

    const formData: EmailTemplateParam[] = [];

    if (model && model.opportunityId) {
      formValues.opportunityId = model.opportunityId;
      formValues.opportunityCode = model.opportunityCode;
    }

    Object.getOwnPropertyNames(formValues).forEach((val, inx) => {
      const data = formValues as Record<string, unknown>;
      if (fieldIsDate(data[val])) {
        formData.push(new EmailTemplateParam(val, (data[val] as Date).toISOString()));
        return;
      }

      if (Array.isArray(data[val])) {
        (data[val] as Record<string, unknown>[]).forEach((item, inx) => {
          Object.getOwnPropertyNames(item).forEach((property) => {
            formData.push(new EmailTemplateParam(`${val}_${inx}_${property}`, String(item[property])));
          })
        });

        return;
      }

      formData.push(new EmailTemplateParam(val, String(data[val])));
    });

    if (model && model.files && model.files.length > 0) {
      formData.push(new EmailTemplateParam("Upload", String(model.files[0].fileUri)));
    }

    apiService.postInteractionWebForm('DealFormSubmit', formData, "campaignsupport@out-bound.co.uk");
  }

  const doSubmit = async (data: SalesIncentiveOpportunityInputModel, upload: any): Promise<SalesIncentiveOpportunityModel> => {

    toast('Success! You have registered your deal.', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "light",
    });

    var returnValue = await apiService.instance.createUpdateSalesIncentiveOpportunity(data, upload);

    let score: number = data.value;

    if (isEdit) {
      if (data.status !== "LOST")
        score = score - opportunities[0].value;
      else
        score = 0 - opportunities[0].value;
    }
    else {
      if (data.status === "LOST")
        score = 0;
    }

    await apiService.postInteraction(InteractionEventType.DealRegistration, "DealRegistration", null, campaignId, score);

    return returnValue;
  }

  const mapFormValues = (formValues: DealRegFormValues): SalesIncentiveOpportunityInputModel | null => {
    try {

      if (!campaignId) {
        return null;
      }

      const formData = {
        ...formValues
      };

      let salesIncentiveOpportunityModel = new SalesIncentiveOpportunityInputModel();

      let statusValue: OpportunityStatus = OpportunityStatus.Open;

      switch (formData.status) {
        case "WON":
          statusValue = OpportunityStatus.Won;
          break;

        case "LOST":
          statusValue = OpportunityStatus.Lost;
          break;
      }

      if (opportunityId) {
        salesIncentiveOpportunityModel.opportunityId = opportunityId;
      }

      salesIncentiveOpportunityModel.status = statusValue;
      salesIncentiveOpportunityModel.oem = formData.manufacturer ? formData.manufacturer : '';
      salesIncentiveOpportunityModel.campaignIdentifier = campaignId;
      salesIncentiveOpportunityModel.customerName = formData.customerName;
      salesIncentiveOpportunityModel.opportunityDate = formData.estimatedCloseDate;
      salesIncentiveOpportunityModel.details = formData.opportunityDetails;
      salesIncentiveOpportunityModel.type = OpportunityType.Opportunity;
      salesIncentiveOpportunityModel.status = statusValue;
      salesIncentiveOpportunityModel.value = formData.estimatedSalesValue;
      salesIncentiveOpportunityModel.products = new Array<OpportunityProductModel>();
      salesIncentiveOpportunityModel.products.push({ description: formData.productType, productSku: "", quantity: 1 });

      return salesIncentiveOpportunityModel;
    }
    catch {
      return null;
    }
  }

  const mapUpload = (formValues: DealRegFormValues): any | null => {
    try {
      return formValues.invoice;
    }
    catch {
      return null;
    }
  }

  return (
    (isEdit && isLoading) ? <LoaderAnimated /> :
      <>
        {(configService.current().opportunityManagementProps.dealRegForm) ?
          <Form
            initialValues={initialValues}
            schema={DealRegFormSchema}
            onSubmit={onSubmit}
            submitButtonText={(!isEdit) ? "SUBMIT" : "UPDATE"}
            submittingButtonText={(!isEdit) ? "SUBMITTING" : "UPDATING"}
            showCancel={true}
          >
            <fieldset>
              {(configService.current().opportunityManagementProps.dealRegForm?.filter((drf: DealRegFormProps) => drf.campaignIdentifier === null || drf.campaignIdentifier === campaignId).reverse()[0].dealRegForm?.map(field => {

                switch (field.dataType) {
                  case DealRegFormFieldDataType.Date:
                    return (<Field
                      name={field.name}
                      label={field.label}
                      component={DateInput} />)
                    break;
                  case DealRegFormFieldDataType.ImageChoice:
                    return (<Field
                      name={field.name}
                      label={field.label}
                      component={ImageRadioInput}
                      valuesImage={field.values} />)
                    break;
                  case DealRegFormFieldDataType.Choice:
                    return (<Field
                      name={field.name}
                      label={field.label}
                      component={SelectInput}
                      values={field.values.filter((val: any) => typeof (val.editOnly) === "undefined" || !val.editOnly || val.editOnly === isEdit)}
                    />)
                    break;
                  case DealRegFormFieldDataType.File:
                    return (<Field
                      name={field.name}
                      label={field.label}
                      component={FileInput}
                      values={field.values.filter((val: any) => typeof (val.editOnly) === "undefined" || !val.editOnly || val.editOnly === isEdit)}
                    />)
                    break;
                  default:
                  case DealRegFormFieldDataType.Text:
                    return (<Field
                      name={field.name}
                      label={field.label}
                      component={TextInput}
                    />)
                    break;
                }
              }))}
            </fieldset>
          </Form> : <></>}
        <Portal>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
              <Modal.Title><div className={configService.current().clientId}><p><b>Close deal</b></p></div></Modal.Title>
            </Modal.Header>
            <Modal.Body><div className={configService.current().clientId}><p>You are about to submit a closed deal which cannot subsequently be edited. Are you sure you want to continue?</p></div></Modal.Body>
            <Modal.Footer>
              <div className={configService.current().clientId}>

                <Button variant="primary" onClick={confirmCloseAndSubmit}>
                  Yes
                </Button>

                <Button variant="secondary" onClick={handleClose} className="clear-button">
                  No
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

        </Portal>
      </>
  )
}