import { FeedItemModel } from 'outbound-api-client/lib/models';
import React from 'react';
import { Thumbnail } from '../../../components';
import { Link } from 'react-router-dom';

export const HeroItem: React.FC<FeedItemModel> = ({
  contentUris,
  title,
  linkToIdentifier,
  actionUri
}) => {
  return (
    <div className='card p-0 m-bottom-0'>
      <div className='pos-relative'>
        <div>
          <Thumbnail cssClass='round-corners' contentUris={contentUris} altText={title} />
        </div>
        <div className='banner-btn'>

{(linkToIdentifier !== null || (actionUri && actionUri.startsWith('/'))) ?
<Link className='m-0 btn btn-primary text-decoration-none text-center d-inline-block' 
              to={(linkToIdentifier !== null) ? `/incentive/${linkToIdentifier}` : actionUri ?? ''}>DISCOVER MORE</Link>
:
<a className='m-0 btn btn-primary text-decoration-none text-center d-inline-block' 
              target={(linkToIdentifier !== null || (actionUri && actionUri.startsWith('/'))) ? '' : '_blank'} 
              href={(linkToIdentifier !== null) ? `/incentive/${linkToIdentifier}` : actionUri ?? ''}>DISCOVER MORE</a>
}
        </div>
      </div>
    </div>
  )
}