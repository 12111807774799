import styled from "styled-components";

export const CampaignEnrolSection = styled.div`

}`


export const DealSummarySection = styled.div`
background-image: url(${props => props.theme.Home.LowerBackgroundImageUrl});
background-repeat: no-repeat;
background-size: cover;
background-position: center;

display: flex;
align-items:center;
justify-content: center;

padding:50px;
gap:50px;

@media only screen and (max-width: 900px) { 
    flex-direction:column;
}

@media only screen and (max-device-width: 900px) { 
    display: block;
    padding: 2px;

    &>div {
        margin: 0px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    &>div:first-child {
        margin-top: 20px;
    }
  }
`

export const PrizeSummarySection = styled.div`
margin-top: 25px;
background-image: url(${props => props.theme.Home.PrizeSummaryBackgroundImageUrl});
background-repeat: no-repeat;
background-size: cover;
background-position: center;

.prize-description {
border-width: ${props => props.theme.DealSummary.BorderWidth};
border-color: ${props => props.theme.DealSummary.BorderColor};
border-style: ${props => props.theme.DealSummary.BorderStyle};

 p {font-weight: bold;}
}

display: flex;
align-items:center;
justify-content: center;

&>div {
    margin: 50px;
}

& h3 {
    background-image: url('${props => props.theme.DividerImageUrl}');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 65px;
        padding-bottom: 20px;
}

& .btn-primary {
    background-color: ${props => props.theme.ButtonBackgroundColour};
    color: ${props => props.theme.ButtonTextColour};
    border-radius: ${props => props.theme.ButtonRadius};
    border: none;
}
`

export const CampaignSubMenu = styled.div`
display: flex;
justify-content: space-around;
margin-bottom: 25px;

& a, a:active, a:visited {
    color: ${props => props.theme.TextColour};
    text-decoration: none;
}

& a:hover {
    text-decoration: underline;
}
`