import { ColoursGT23 } from "../style/colours.gt23";
import { MonteCarloTheme } from "../style/theme.montecarlo";
import { string, number, date } from 'yup';
import { ConfigProps, DealRegFormFieldDataType, LayoutType } from "./config.service.types";
import { DealSummaryMode } from "../modules/OpportunityManagement/DealSummary.types";

export const SCCMonteCarloConfig: ConfigProps = {
  appUri: `${process.env.REACT_APP_URL}`,
  apiUri: `${process.env.REACT_APP_API_URL}`,
  clientId: "scc-montecarlo",
  clientSecret: "GI4X9391eIcMLAozSUxtPSMR6x5Qhz2cyJH15BLBqRz+NdH+CQWVZACUfn901/6/QMpVsxQakhBDAQrpLRoGQw==",
  applicationTitle: "SCC Monte Carlo",
  campaignIdentifier: "",
  layoutType: LayoutType.Legacy,
  theme: MonteCarloTheme,
  colours: ColoursGT23,
  learnProps: {
    groupCategories: false
  },
  enableProps: {
    groupByCategory: true,
    searchEnabled: false,
    downloadIcons: true,
    tiles: null
  },
  footerProps: {
    smallPrint: "&copy; Outbound Field Marketing Services",
    helpUrl: "mailto:campaignsupport@out-bound.co.uk?subject=SCC DS Contact",
    helpText: "Help",
    privacyUrl: "https://www.scc.com/privacy-notice-employees/",
    privacyText: "Privacy Information",
    termsUrl: "https://sccmontecarlo.blob.core.windows.net/assets/SCC%20Monte%20Carlo%20Terms%20%26%20Conditions.pdf",
    termsText: "Terms & Conditions",
    cookiePolicyUrl: null,
    cookiePolicyText: null
  },
  campaignProps: {
    termsButtonText: "ENTER",
    termsButtonSubmittingText: "ENTERING",
    prizeSection: {
      show: true,
      title: "THE PRIZE",
      intro: "Learn more about the amazing trip we have planned for you!",
      dividerImageUrl: "https://sccmontecarlo.blob.core.windows.net/images/divider.png"
    },
    dealSummarySections: [
      {
        title: "MY DEALS SUMMARY",
        logoUrl: null,
        oem: "",
        playbookUrl:"https://sccmontecarlo.blob.core.windows.net/assets/16320_HP%20SCC%20Magnificent%20Monaco%20-%20playbook.pdf",
        borderColour: "#bd422a",
        mode: DealSummaryMode.MyDeals
      }
    ]
  },
  opportunityManagementProps: {
    vendors: [],
    dealRegForm: [{
      campaignIdentifier: null,
      dealRegFormSchema: {
        status: string().required('You must select a status'),
        customerName: string().required('You must enter a customer name'),
        productType: string().required('You must enter a product type'),
        opportunityDetails: string().required('You must enter the opportunity details'),
        estimatedSalesValue: number().positive("Amount must be greater than zero").required('You must enter a value').typeError('Amount must be a number only'),
        estimatedCloseDate: date().required('You must enter an estimated close date').nullable(true),
      },
      dealRegForm: [
        { name: "status", label: "Status", dataType: DealRegFormFieldDataType.Text, values: [{ identifier: "OPEN", label: "Open", value: "OPEN" }, { identifier: "WON", label: "Won", value: "WON" }, { identifier: "LOST", label: "Lost", value: "LOST" }] },
        { name: "customerName", label: "Customer Name", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "productType", label: "Product Type", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "opportunityDetails", label: "Opportunity Details", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedSalesValue", label: "Estimated Sales Value (£)", dataType: DealRegFormFieldDataType.Text, values: [] },
        { name: "estimatedCloseDate", label: "Estimated Close Date", dataType: DealRegFormFieldDataType.Date, values: [] }
      ],
      dealRegFormInitialValues: {
        status: 'OPEN',
        manufacturer: 'NONE',
        customerName: '',
        productType: '',
        opportunityDetails: '',
        estimatedSalesValue: 0,
        estimatedCloseDate: new Date()
      },
      dealsEditable: true
    }]
  },
  navigationProps: {
    showSalesTools: false,
    showQuiz: false,
    showLeaderboard: true
  }
}