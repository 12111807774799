import React from 'react';
import { CampaignModel as CampaignProps, ContentType } from 'outbound-api-client/lib/models';
import { Card, Field, Form } from '../../components';
import { CampaignEnrolmentSchema } from './CampaignEnrolment.schema';
import { CheckboxInput } from '../../components/CheckboxInput';
import { useContentTemplate } from '../ContentTemplate';
import { CampaignEnrolProps } from './Campaigns.types';
import { configService } from '../../services';
import { CampaignEnrolSection } from './Campaign.styles';

export const CampaignEnrol: React.FC<CampaignEnrolProps & CampaignProps> = (props) => {

  const { ContentTemplateContent } = useContentTemplate(null, ContentType.TermsAndConditions);
  
  return (
    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
      <CampaignEnrolSection className='p-x-1'>
        <div className='text-center'>
          <ContentTemplateContent />
          <div>
            <Form
              initialValues={{
                acceptedTerms: false,
              }}
              schema={CampaignEnrolmentSchema}
              onSubmit={props.onEnrolSubmit}
              showCancel={false}
              submitButtonText={configService.current().campaignProps.termsButtonText}
              submittingButtonText={configService.current().campaignProps.termsButtonSubmittingText}
            >
              <fieldset>
                <Field
                  name='acceptedTerms'
                  component={CheckboxInput}
                  values={[{
                    identifier: 'terms',
                    label: 'I have read and understand the terms and conditions',
                    value: 'true'
                  }]}
                  boxPosition='right'
                />
              </fieldset>
            </Form>
          </div>
        </div>
      </CampaignEnrolSection>
    </Card>
  )
}