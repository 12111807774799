import { TrainingEpisodeModel, TrainingEpisodeResponseDataModel, RewardType } from 'outbound-api-client/lib/models';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Row, Cell, LoaderAnimated } from '../../../components';

export const QuizEnd: React.FC<TrainingEpisodeModel & TrainingEpisodeResponseDataModel> = ({
  episodeCompletedText,
  maxScore,
  episodeScore,
  allocatedRewards
}) => {

  const { campaignId, episodeId } = useParams();
  const navigate = useNavigate();

  const quizDone = () => {
    if (campaignId)
    {
      navigate(`/incentive/${campaignId}`);
    }
    else
    {
      navigate(`/learn`);
    }
  };

  return (
    (maxScore === 0) ? <LoaderAnimated /> : 
    <div>
      <Row mainAlignment='center'>
        <Cell className='text-center'>
          <p>You scored {episodeScore} out of {maxScore}!</p>
          <p>{episodeCompletedText}</p>

          {(allocatedRewards !== null && allocatedRewards.filter((x) => { return x.rewardType === RewardType.InstantWin}).length === 1) ? <><p>You are one of the first to answer all questions correctly, first time, you get to spin the wheel - Good Luck!​</p><Link className='btn btn-primary m-right-2' to={`/incentive/${campaignId}/learn/${episodeId}/${allocatedRewards.filter((x) => { return x.rewardType === RewardType.InstantWin})[0].rewardId}/instantwin`}>INSTANT WIN AVAILABLE!</Link></> : 
          (episodeScore === maxScore) ? <p>Well done! You completed the quiz with full marks! Come back next month for another quiz.</p> : <p>Thank you for taking the quiz. Remember to check out the Enablement Tools and come back next month for another quiz!</p>}

          <a className='btn btn-hollow btn-primary' onClick={quizDone}>DONE</a>
        </Cell>
      </Row>
    </div>
  )
}