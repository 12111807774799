import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Row, Cell, CardLoader, GridContainerNoFlex } from '../components';
import { useContentTemplate, useUserStatusRefresh } from '../modules';
import { useTraining } from '../modules/Training';
import { configService } from '../services';
import { LayoutType } from '../services/config.service.types';
import { useAppDispatch } from '../store';

export const Learn: React.FC = () => {
  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const { ContentTemplateContent } = useContentTemplate("Quiz_EN_GB");
  const { AvailableTraining, isLoading } = useTraining(dispatch, campaignId);
  const isLegacy = configService.current().layoutType === LayoutType.Legacy;
  useUserStatusRefresh(dispatch);

  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setPageLoading(false);
    }
  }, [isLoading]);
  
  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Learn</title>
      </Helmet>
      <Row>
        <Cell className='text-center' baseSize={12}>
          <ContentTemplateContent />
        </Cell>
      </Row>
      <Row>
        <Cell baseSize={12}>
          {isPageLoading ? (
            <Row>
              <Cell baseSize={12}>
                <Row mainAlignment='center'>
                  <div className='font-title placeholder' style={{width:"16ch"}} />
                </Row>
                <Row wrap="false" className='overflow-hidden'>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                </Row>
                <Row mainAlignment='center'>
                  <div className='font-title placeholder' style={{width:"16ch"}} />
                </Row>
                <Row wrap="false" className='overflow-hidden'>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                </Row>
              </Cell>
            </Row>
          ) : (
            (!isLegacy) ? 
            <AvailableTraining /> :

            <GridContainerNoFlex className='m-x-auto p-top-1'>
              <AvailableTraining />
            </GridContainerNoFlex>
          )}
        </Cell>
      </Row>
    </div>
  )
}