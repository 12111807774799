import styled from "styled-components";

export const LogDealActionBarContainer = styled.div`
    background-color: ${props => props.theme.Home.ActionBarBackgroundColor};
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    & a {
        border: #1C0D40 1px solid;
        padding: 10px 50px;
        border-radius: 5px;
        background-color: #1C0D40;
        color: #fff;
        text-decoration: none;
    }

    a {
        background-color: ${props => props.theme.ButtonBackgroundColour};
        color: ${props => props.theme.Home.ActionBarTextColor};
        border-radius: ${props => props.theme.ButtonRadius};
        border: none;
    }
`

export const DealSummarySection = styled.div`
background-image: url(${props => props.theme.Home.LowerBackgroundImageUrl});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
align-items:center;
justify-content: center;
margin-bottom: 25px;

&>div:first-child {
    margin-right: 50px;
}

&>div:last-child {
    margin-right: 0px;
}

@media only screen and (max-device-width: 900px) { 

    flex-wrap: wrap;

    &>div:first-child {
        margin-right: 0px;
        margin-bottom: 25px;
    }
  }
`

export const DealSummaryContainer = styled.div`

background-color: ${props => props.theme.DealSummary.BackgroundColor};
border-width: ${props => props.theme.DealSummary.BorderWidth};
opacity: ${props => props.theme.DealSummary.Opacity};
color: ${props => props.theme.HighlightTextColour};
border-style: solid;
text-align: center;
width:100%;
max-width:480px;
display: flex;
flex-direction: column;
justify-items: center;
align-items: center;


&.mode-mypoints {
    padding: 50px 50px 20px 50px;

  
    .download-playbook-link {
        .btn-primary
        {
            color: #000 !important;
            white-space: nowrap;
            width: auto;
        }
    }
    
}

@media only screen and (max-device-width: 900px) { 
    padding: 5px;
    width: 95%;
  }

& img {
    width: auto;
    height: 75px;
}

& .download-playbook-link {

    .btn-primary {
        margin-top: 0px;
        background-color: ${props => props.theme.ButtonBackgroundColour};
        color: ${props => props.theme.ButtonTextColour};
        border-radius: ${props => props.theme.ButtonRadius};
        border: none;
    }

    margin-bottom: 15px;
    text-transform: uppercase;
    padding: 5px;

    & a {
        color: ${props => props.theme.ButtonTextColour};
        text-decoration: none;
    }
}

& .stat-list-container {
    display: flex;
}

& .stat-list {
    margin-bottom: 15px;
}

& .stat-row {
    font-weight: bold;
}

& .stat-value {
    margin-right: 5px;
}

& .stat-label::before {
    content: " - ";
  }

  & .stat-label {
    text-align: left;
  }

  .dealSummaryTitle {
    background-image: url('${props => props.theme.DividerImageUrl}');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 65px;
    padding-bottom: 20px;
  }

`