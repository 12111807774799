import { EmailSendModel, EmailTemplateParam, SendGridEmailTemplate } from 'outbound-api-client/lib/models';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoaderAnimated } from '../../components';
import { apiService } from '../../services';
import { CSVReader } from 'react-papaparse';
import { EmailTemplateSectionContainer } from './Emailer.styles';

export const EditSend: React.FC = () => {

    const { templateId } = useParams();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [, setEmailTemplate] = useState<SendGridEmailTemplate>();
    const [params, setParams] = useState<Array<EmailTemplateParam>>(new Array<EmailTemplateParam>());
    const [recipients, setRecipients] = useState<Array<string>>(new Array<string>());

    const nameInput = useRef<HTMLInputElement>(null);
    const addRecipientInput = useRef<HTMLInputElement>(null);
    const testEmailInput = useRef<HTMLInputElement>(null);

//    const sendButtonDiv: React.RefObject<HTMLDivElement>;
    //const sendButtonInnerDiv: React.RefObject<HTMLDivElement>;
    //const addRecipButton: React.RefObject<HTMLButtonElement>;
    const buttonRef = useRef<any>(null);

    const initialiseTemplate = (emailTemplate: SendGridEmailTemplate) => {
        const allParams = new Array<EmailTemplateParam>();
        const content = emailTemplate.version[0].htmlContent;
        const re = /{{[{]?(.*?)[}]?}}/g;
        let matches;

        while (Boolean((matches = re.exec(content)))) {
            if (matches) {
                allParams.push(new EmailTemplateParam(matches[1], ""));
            }
        }

        setParams(allParams);
    };

    const onAddRecipient = () => {
        if (addRecipientInput.current?.value) {
            setRecipients([...recipients, addRecipientInput.current?.value]);
        }
    };

    const onSendTestEmailSend = () => {

        if (testEmailInput.current?.value && templateId) {
            const testSend = new EmailSendModel();

            testSend.singleRecipient = testEmailInput.current.value;
            testSend.templateId = templateId;
            testSend.content = params;

            apiService.instance.testEmailSend(testSend)
                .then((emailSend) => {
                    //console.log(emailSend);
                });
        };
    }

    const onCreateUpdateEmailSend = () => {
       if (templateId) {
            const testSend = new EmailSendModel();

            testSend.name = name;
            testSend.recipientList = recipients;
            testSend.templateId = templateId;
            testSend.content = params;

            apiService.instance.emailSend(testSend)
                .then((emailSend) => {
                    //console.log(emailSend);
                });
        };

    };

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {

        if (nameInput.current)
        {
            setName(e.currentTarget.value);
        }
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {

        let key = e.currentTarget.name;
        let value = e.currentTarget.value;

        const currentParams = params;

        for (var i = 0; i < currentParams.length; i++) {
            if (currentParams[i].key === key)
                currentParams[i].value = value;
        }

        setParams(params);
    };

    useEffect(() => {
        const fetchData = async () => {

            apiService.instance.getEmailTemplates()
                .then((emailTemplates) => {
                    setLoading(false);
                    const emailTemplate = emailTemplates.filter((et) => et.templateId === templateId)[0];
                    setEmailTemplate(emailTemplate);
                    initialiseTemplate(emailTemplate);
                });
        }

        fetchData()
            .catch(console.error);
    }, []);

    const handleOnFileLoad = (data: any) => {

        if (buttonRef.current)
        {
        let newRecipient = new Array<string>();

        for (let i = 0; i < data.length; i++) {
            if (data[i].data[0] !== "") {
                newRecipient.push(data[i].data[0]);
            }
        }

        setRecipients([...recipients, ...newRecipient]);

        //console.log('---------------------------')
        //console.log(data)
        //console.log('---------------------------')
        buttonRef.current.removeFile();
    }
    }

    
    const handleOpenDialog = (e: any) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

     const handleOnRemoveFile = (data: any) => {
        //console.log('---------------------------')
        //console.log(data)
        //console.log('---------------------------')
    }

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        //console.log(err)
    }


    return (
        (loading) ? <LoaderAnimated /> :
            <>
                <EmailTemplateSectionContainer>
                    <div>
                        Send {templateId}
                    </div>
                    <div>
                        Title: <input ref={nameInput} type="text" onChange={handleNameChange} />
                    </div>
                </EmailTemplateSectionContainer>
                <EmailTemplateSectionContainer>
                    {
                        params.map((item: EmailTemplateParam) => {
                            return (<div key={item.key}><div>{item.key}</div><div><input name={`${item.key}`} onChange={handleChange} type="text" /></div></div>)
                        })
                    }
                </EmailTemplateSectionContainer>
                <EmailTemplateSectionContainer>
                    <div>
                        {
                            recipients.map((recipient) => {
                                return (<div key={recipient}><div>{recipient}</div></div>)
                            })
                        }

                        <input type="text" ref={addRecipientInput} /><button onClick={onAddRecipient}>Add Recip</button>

                    </div>

                    <div>
                            <CSVReader
                                ref={buttonRef}
                                onFileLoad={handleOnFileLoad}
                                onError={handleOnError}
                                noClick
                                noDrag
                                onRemoveFile={handleOnRemoveFile}
                            >
                                <button
                                    type="button"
                                    onClick={handleOpenDialog}
                                    className='yellow-button'
                                    style={{
                                        borderRadius: 0,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        width: '40%',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                >
                                    Import CSV
                                </button>

                            </CSVReader>
                        </div>
                        </EmailTemplateSectionContainer>
                        <EmailTemplateSectionContainer>

                    <div>
                        <input type="text" ref={testEmailInput} /><button onClick={onSendTestEmailSend}>Send Test</button>
                    </div>

                    <button onClick={onCreateUpdateEmailSend}>Send for approval</button>
                    </EmailTemplateSectionContainer>
            </>
    );

}