import React from 'react';
import { FormikHelpers } from 'formik';
import { Form, Field, TextInput, TextInputReadOnly, CheckboxInput } from '..';
import { UserProfileFormSchema, UserProfileFormValues } from './Profile.schema';
import { toast } from 'react-toastify';
import { useProfile, useApplicationProfile } from '../../modules';
import { ApplicationProfileFieldModel, InteractionEventType, UserPreferencesModel, UpdateUserModel } from 'outbound-api-client/lib/models';
import { apiService } from '../../services';

export const ProfileForm: React.FC = () => {

  const { user, isProfileLoading } = useProfile();
  const { applicationProfile, isApplicationProfileLoading } = useApplicationProfile();

  const onSubmit = async (formValues: UserProfileFormValues, formikBag?: FormikHelpers<UserProfileFormValues>) => {
    try {
      //const formData = {
      //  ...formValues
      //};

      apiService.postInteraction(InteractionEventType.WebFormSubmit, 'ProfilePageUpdate');

      let updatePreferenceData = new UserPreferencesModel();

      let industrySpecialism = new ApplicationProfileFieldModel();
      industrySpecialism.name = "Industry Specialism";
      industrySpecialism.values = formValues.industrySpecialism;

      let interestAreas = new ApplicationProfileFieldModel();
      interestAreas.name = "Interest Areas";
      interestAreas.values = formValues.interestAreas;

      updatePreferenceData.additionalProfileFields = new Array<ApplicationProfileFieldModel>();
      updatePreferenceData.additionalProfileFields.push(industrySpecialism);
      updatePreferenceData.additionalProfileFields.push(interestAreas);

      apiService.instance.updateUserPreferences(updatePreferenceData);

      if (user?.displayName !== formValues.displayName) {
        let updateUserAccount = new UpdateUserModel();
        updateUserAccount.email = user?.email;
        updateUserAccount.userName = user?.userName;
        updateUserAccount.displayName = formValues.displayName;
        await apiService.instance.updateUserAccount(updateUserAccount);
        window.location.href = "/";
      }
      else {
        toast('Success! Your profile changes have been saved.', { type: toast.TYPE.SUCCESS });
      }



    } catch {
      toast("We can't submit right now. Try again later.", { type: toast.TYPE.ERROR });
    }
  }

  /*return ( isProfileLoading || isApplicationProfileLoading ? <span>Loading ....</span> : 
    <>
    {applicationProfile?.additionalProfileFields.map((field: any) => {

      let userValue = user?.profileData?.filter(userField => userField.key === field.name);

      return <div>
        <div><b>{field.name} ({userValue})</b></div>

        { field.values.map((fieldValue: string) => {
          return <div><input type="checkbox" />&nbsp;{fieldValue}</div>
        }) }
      
        </div>
    })}
    
    </>
  )
}*/

  let userIndustrySpecialism: string[] = [];
  let userInterestAreas: string[] = [];

  if (user?.profileData?.filter(userField => userField.key === "industry Specialism")[0]?.value) {
    userIndustrySpecialism = user?.profileData?.filter(userField => userField.key === "industry Specialism")[0]?.value.split(',');
  }

  if (user?.profileData?.filter(userField => userField.key === "interest Areas")[0]) {
    userInterestAreas = user?.profileData?.filter(userField => userField.key === "interest Areas")[0].value.split(',');
  }

  return (isProfileLoading || isApplicationProfileLoading ? <span>Loading ....</span> :
    <>
      <div className='card'>
        <h4 className='text-center'>Your Profile</h4>
        <Form
          initialValues={{
            firstName: `${user?.firstName}`,
            lastName: `${user?.lastName}`,
            displayName: (user?.displayNameSafe) ? `${user?.displayNameSafe}` : "",
            companyName: (user?.companyName) ? `${user?.companyName}` : "",
            industrySpecialism: userIndustrySpecialism,
            interestAreas: userInterestAreas
          }}
          schema={UserProfileFormSchema}
          onSubmit={onSubmit}
          showCancel={false}
        >
          <fieldset>
            <Field
              name='firstName'
              label='First Name'
              component={TextInputReadOnly}
            />
            <Field
              name='lastName'
              label='Last Name'
              component={TextInputReadOnly}
            />
            <Field
              name='displayName'
              label='Display Name'
              component={TextInput}
            />
            <Field
              name='companyName'
              label='Company Name'
              component={TextInputReadOnly}
            />
            <Field
              name='industrySpecialism'
              label='Industry Specialism'
              component={CheckboxInput}
              valuesSimple={applicationProfile?.additionalProfileFields.filter(f => f.name === "Industry Specialism")[0]?.values}
            />
            <Field
              name='interestAreas'
              label='Interest Areas'
              component={CheckboxInput}
              valuesSimple={applicationProfile?.additionalProfileFields.filter(f => f.name === "Interest Areas")[0]?.values}
            />
          </fieldset>
        </Form>
      </div>
    </>
  )
}