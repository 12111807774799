import React from 'react';
import { Helmet } from 'react-helmet';
import { ProfileForm, Referral } from '../components';
import { configService } from '../services';

export const Profile: React.FC = () => {

  return (
    <div className='row'>
      <div className='col-12 col-md-6'>
        <Helmet>
          <title>{configService.current().applicationTitle} - Profile</title>
        </Helmet>
        <ProfileForm />
      </div>
      <div className='col-md-6'>
        <Referral />
      </div>
    </div>
  )
}