import { FeedDisplayType, FeedItemModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { getFeedItems } from "./Feed.services";
import { FeedItemCollection } from "./components/FeedItemCollection.component";
import { PinnedFeed } from "./components/PinnedFeed.component";

export const useFeedItems = () => {
  const [feedItems, setFeedItems] = useState<FeedItemModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFeedItems(FeedDisplayType.Primary)
      .then((feedItems: FeedItemModel[]) => {
        setFeedItems(feedItems.filter(x => { return ((!x.datePublished || x.datePublished <= new Date()) && (!x.dateExpires || x.dateExpires >= new Date())) }));
        setHasError(false);
      })
      .catch((err: any) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const FeedContent = () => {
    return <FeedItemCollection FeedItems={feedItems} />
  }

  return {
    isLoading,
    hasError,
    hasContent: !!feedItems.length,
    FeedContent
  }
}

export const useHeroItems = () => {
  const [heroItems, setHeroItems] = useState<FeedItemModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFeedItems(FeedDisplayType.Hero)
      .then((feedItems: FeedItemModel[]) => {
        setHeroItems(feedItems.filter(x => { return ((!x.datePublished || x.datePublished <= new Date()) && (!x.dateExpires || x.dateExpires >= new Date())) }));
        setHasError(false);
      })
      .catch((err: any) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const HeroFeed = () => {
    return <PinnedFeed FeedItems={heroItems} />
  }

  return {
    isLoading,
    hasError,
    hasContent: !!heroItems.length,
    HeroFeed,
  }
}