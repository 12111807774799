import { DefaultTheme } from 'styled-components';
import { BaseOptions } from '../declarations/styled';
import { Colours } from './colours';

const ThemeColours = {
  PrimaryColour: Colours.PRIMARY,
  SecondaryColour: Colours.SECONDARY,
  TertiaryColour: Colours.TERTIARY,
  TextColour: Colours.BLACK,
  HighlightColour: Colours.PRIMARY,
  HighlightTextColour: Colours.BLACK,
  ButtonBackgroundColour: "#1D64E8",
  ButtonTextColour: Colours.WHITE,
  ButtonRadius: "0px",
};

const AnimationSettings = {
  AnimationTimingFast: '0.3s',
  AnimationTimingMedium: '0.5s',
  AnimationTimingSlow: '1s',
}

const BaseSettings: BaseOptions = {
  BorderRadius: '0.625em',
  DropShadowColor: Colours.BLACK,
  DropShadowAlpha: 0.2,
  DropShadowPlacement: '0px 3px 6px',
}

export const SCCIncentiveTheme: DefaultTheme = {
  Id: "scc-incentive",
  ...ThemeColours,
  ...AnimationSettings,
  Base: BaseSettings,
  Grid: {
    Columns: 12,
    GutterSize: '1em',
    Breakpoints: {
      SM: 600,
      MD: 760,
      LG: 900,
      XL: 1200,
      MAX: 1500,
    },
  },
  Splash: {
    BackgroundImageUrl: "https://sccincentive.blob.core.windows.net/images/splash-background.jpg",
    LogoImageUrl: "https://sccincentive.blob.core.windows.net/images/ident.png",
    VideoUrl: "https://sccincentive.blob.core.windows.net/video/splash.mp4"
  },
  Header: {
    BackgroundColour: Colours.PRIMARY,
    TextColour: Colours.WHITE,
    LockupImageUrl: "https://sccincentive.blob.core.windows.net/images/HPE,%20HPI,%20Intel,%20Poly,%20SCC.png",
    LockupImageUrl_Additional: "https://sccincentive.blob.core.windows.net/images/HP,%20Poly.png"
    // LockupImageUrl_Additional: ""
  },
  Home: {
    LowerBackgroundImageUrl: "https://sccincentive.blob.core.windows.net/images/home-lower-bg.png",
    PrizeSummaryBackgroundImageUrl: "https://sccincentive.blob.core.windows.net/images/home-prizes-bg.png",
    ActionBarBackgroundColor: "#d1b895",
    ActionBarTextColor: Colours.WHITE
  },
  Nav: {
    TextColour: Colours.WHITE,
    HoverColour: Colours.WHITE,
    TextDecoration: 'none',
    TextDecorationOnHover: 'none',
  },
  Card: {
    BorderRadius: BaseSettings.BorderRadius,
    ContentMargin: '1em',
    ContentPadding: '1em',
    BackgroundColor: Colours.WHITE,
    HasDropShadow: false,
    DropShadowColor: BaseSettings.DropShadowColor,
    DropShadowAlpha: BaseSettings.DropShadowAlpha,
    DropShadowPlacement: BaseSettings.DropShadowPlacement,
  },
  ListItem: {
    BorderWidth: "1px",
    BorderColor: "#f9cea4",
    BorderStyle: "solid",
    BorderRadius: "5px"
  },
  DividerImageUrl: "",
  DealSummary: {
    BackgroundColor: "#fff",
    BorderWidth: "3px",
    BorderRadius: "0px",
    Opacity: "80%",
    BorderColor: "transparent",
    BorderStyle: "solid"
  },
  Leaderboard: {
    RowBackgroundColour: "#f8f4ee"
  }
};
