import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card, CardLoader, Cell, GridContainerNoFlex, Row, Thumbnail } from '../components';
import { useAssets, useContentTemplate, useUserStatusRefresh } from '../modules';
import { useAppDispatch } from '../store';
import { InteractionEventType } from 'outbound-api-client/lib/models';
import { apiService, configService } from '../services';
import { ConfigEnableTileProps, LayoutType } from '../services/config.service.types';

export const Enable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const { ContentTemplateContent } = useContentTemplate("Sales_Tools_EN_GB");
  const isLegacy = configService.current().layoutType === LayoutType.Legacy;

  const {
    AssetContent,
    isLoading,
    setQuery
  } = useAssets(campaignId ?? null);

  useUserStatusRefresh(dispatch);

  const [isPageLoading, setPageLoading] = useState(true);
  const queryInputRef = useRef<HTMLInputElement>(null);

  let tiles: Array<ConfigEnableTileProps> | null = null;
  let searchEnabled = false;

  if (configService.current().enableProps) {
    searchEnabled = configService.current().enableProps?.searchEnabled ?? false;

    if (configService.current().enableProps?.tiles) {
      tiles = configService.current().enableProps?.tiles ?? null;
    }
  }

  useEffect(() => {
    if (!isLoading) {
      setPageLoading(false);
    }
  }, [isLoading]);

  const doSearch = async (term: string | null) => {
    await apiService.postInteraction(InteractionEventType.Search, "Asset", term);
    setQuery(term);
  }

  const onSearch = async () => {
    if (queryInputRef.current?.value) {
      await doSearch(`${queryInputRef.current?.value}*`);
    }
  }

  const onChange = async () => {
    let inputQuery: string | null = null;

    if (queryInputRef.current?.value) {
      inputQuery = `${queryInputRef.current?.value}*`;
    }

    if (inputQuery === null)
      await onSearchClear();
  }

  const onKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      if (queryInputRef.current?.value) {
        await doSearch(`${queryInputRef.current?.value}*`);
      }
    }
  }

  const onSearchClear = async () => {

    if (queryInputRef.current) {
      queryInputRef.current.value = "";
    }
    setQuery(null);

  }

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Enable</title>
      </Helmet>
      {(tiles) ?
        <>
          <Row>
            <Cell className='text-center' baseSize={12}>
              <h2 className="font-title m-bottom-0">Tools</h2>
              <p className='m-bottom-2'>Use the below tiles to access some useful enablement tools.</p>
            </Cell>
          </Row>
          <Row mainAlignment='center'>
            {tiles.map(((t, index) => {
              return (<Cell key={"tile" + index}>
                {(t.url.startsWith("http")) ?
                  <a href={t.url} target={t.target} className='color-text text-decoration-none'>
                    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
                      <div>
                        <Thumbnail contentUris={t.thumnbails} altText={t.title} />
                        <div className='p-x-1'>
                          <h3 className='font-heading'>{t.title}</h3>
                          <p className='font-subheading'>{t.summary}</p>
                        </div>
                      </div>
                    </Card>
                  </a>
                  :
                  <Link to={t.url} target={t.target} className='color-text text-decoration-none'>
                    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-2'>
                      <div>
                        <Thumbnail contentUris={t.thumnbails} altText={t.title} />
                        <div className='p-x-1'>
                          <h3 className='font-heading'>{t.title}</h3>
                          <p className='font-subheading'>{t.summary}</p>
                        </div>
                      </div>
                    </Card>
                  </Link>
                }
              </Cell>)
            }))}
          </Row></>
        : <></>}

      <Row>
        <Cell baseSize={12}>
          {isPageLoading ? (
            <Row>
              <Cell baseSize={12}>
                <Row mainAlignment='center'>
                  <div className='font-title placeholder' style={{ width: "16ch" }} />
                </Row>
                <Row wrap="false" className='overflow-hidden'>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                </Row>
                <Row mainAlignment='center'>
                  <div className='font-title placeholder' style={{ width: "16ch" }} />
                </Row>
                <Row wrap="false" className='overflow-hidden'>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                  <Cell baseSize={6} SMSize={4} LGSize={3}>
                    <CardLoader />
                  </Cell>
                </Row>
              </Cell>
            </Row>
          ) : (
            <>
              <Row>
                <Cell className='text-center' baseSize={12}>
                  <ContentTemplateContent />
                </Cell>
              </Row>
              {(searchEnabled) ?
                <Row>
                  <Cell className='text-center' baseSize={12}>
                    <div className="searchContainer">
                      <button type="submit" value="Search" className="searchButton" onClick={onSearch}><i className="fa fa-search searchIcon"></i></button>

                      <input ref={queryInputRef} className="searchBox" type="search" name="search" placeholder="Search" onChange={onChange} onKeyPress={onKeyPress} />

                    </div>
                  </Cell>
                </Row> : <></>
              }
              {(!isLegacy) ? <AssetContent /> :
                <GridContainerNoFlex className='m-x-auto p-top-1'>
                  <AssetContent />
                </GridContainerNoFlex>}
            </>
          )}
        </Cell>
      </Row>
    </div>
  )
}